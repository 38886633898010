import { information } from "../../information";

/*--------------------
* Footer Section
----------------------*/
function Footer() {
  return (
    <footer className="footer bg-slate-900 border-t border-white border-opacity-10 py-4">
      <div className="container">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-6 flex pb-3 md:pb-0">
            <div className="flex justify-center md:justify-start w-full">
              <a
                className="text-white text-opacity-90 px-2 mr-5 text-[16px] text-2xl text-center my-auto"
                href="https://www.linkedin.com/showcase/phuquocdevs/"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="text-white text-opacity-90 px-2 mr-5 text-[16px] text-2xl text-center my-auto"
                href="https://mstdn.business/@phuquocdevs"
                rel="me"
                target="_blank"
              >
                <i class="fa-brands fa-mastodon"></i>
              </a>
              <a
                className="text-white text-opacity-90 px-2 mr-5 text-[16px] text-2xl text-center my-auto"
                href="https://www.facebook.com/people/PhuquocDevs/61564288350409/"
                rel="noreferrer"
                target="_blank"
              >
                <i class="fa-brands fa-facebook"></i>
              </a>
              <a
                className="text-blue-500 text-opacity-90 mr-5 text-[16px]"
                href="https://matrix.to/#/%23phuquocdevs:matrix.org"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="img/matrix-favicon.svg"
                  alt="matrix-icon"
                  title="matrix"
                  srcSet="img/matrix-favicon.svg"
                  className="w-10 bg-white p-1"
                />
              </a>
            </div>
          </div>
          <div className="my-auto col-span-12 md:col-span-6 text-center md:text-right">
            <p className="m-0 text-white text-opacity-75 text-center">
              © {new Date().getFullYear()} copyright all right reserved by {information.companyName}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
